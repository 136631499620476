export const studioTypes = [
  {
    title: 'Digital Shows',
    key: 'show'
  },
  {
    title: 'Movies & Series',
    key: 'movie'
  }
];
