export const agencyTypes = [
  {
    title: 'Video Ads And Campaigns',
    key: 'videoAdsAndCampaigns'
  },
  {
    title: 'Digital And Branding',
    key: 'digitalAndBranding'
  }
];
